import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../components/';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styles from '../stylesheets/pages/page.module.scss';
import { HEADER_NAV_DATA, QUICK_NAVIGATION_404 } from '../utils';

const NotFoundPage = () => {
	const intl = useIntl();
	const { aboutUs } = HEADER_NAV_DATA;
	return (
		<Layout>
			<SEO title="404: Not found" />
			<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_404}>
				<div className={styles.pageContent}>
					<ContentLayout>
						<h1>{intl.formatMessage({ id: 'not_found_page_title' })}</h1>
						<p>
							{`${intl.formatMessage({ id: 'not_found_page_subtitle' })} `}
							<Link to={aboutUs.route} className={styles.errorLink}>
								{intl.formatMessage({ id: `nav.${aboutUs.translationKey}` })}
							</Link>
						</p>
					</ContentLayout>
				</div>
			</QuickBottomNavigationWrapper>
		</Layout>
	);
};

export default NotFoundPage;
