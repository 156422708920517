import { graphql } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import NotFoundPage from '../../pages/404';
import {
	CONTENTFUL_STORY_PHOTO_TYPE,
	QUICK_NAVIGATION_STORIES,
	ROUTES,
	getLocalizedDataFromContentfulEdges,
} from '../../utils';
import Image from '../Image/Image';
import Layout from '../layout';
import RichTextRenderer from '../RichTextRenderer';
import SEO from '../seo';
import styles from './StoriesTemplate.module.scss';
import { ContentLayout, QuickBottomNavigationWrapper } from '..';

export const query = graphql`
	query($contentful_id: String!) {
		allContentfulStory(filter: { contentful_id: { eq: $contentful_id } }) {
			edges {
				node {
					node_locale
					slug
					id
					title
					description
					createdAt(formatString: "MMM D")
					contentPhotoType
					photoFull: contentPhotoAsset {
						fluid(maxWidth: 2000, quality: 90) {
							...GatsbyContentfulFluid
						}
					}
					photo: contentPhotoAsset {
						fluid(maxWidth: 700, quality: 100) {
							...GatsbyContentfulFluid
						}
					}
					content {
						raw
						references {
							... on ContentfulAsset {
								__typename
								contentful_id
								photoFluid: fluid(maxWidth: 700, quality: 100) {
									...GatsbyContentfulFluid
								}
								photoFluidPreview: fluid(maxWidth: 2000, quality: 90) {
									...GatsbyContentfulFluid
								}
							}
							... on ContentfulStoryVideoAsset {
								__typename
								contentful_id
								id
								title
								asset {
									file {
										url
									}
								}
							}
							... on ContentfulStoryVideoLink {
								__typename
								contentful_id
								id
								videoUrl
								title
							}
						}
					}
				}
			}
		}
	}
`;

const StoriesTemplate = ({ location, data }) => {
	const intl = useIntl();
	const { node } = getLocalizedDataFromContentfulEdges(data.allContentfulStory.edges, intl.locale)[0];
	const { title, createdAt, content, contentPhotoType, photo, photoFull, description, slug } = node;
	const isViableStory = title && content;

	if (!isViableStory) {
		return <NotFoundPage />;
	}
	return (
		<Layout>
			<SEO title={title} description={description} shouldShowTitleTemplate={false}>
				{slug && <link rel="canonical" href={location.href} />}
				<meta property="og:image" name="image" content={photo && `http:${photo?.fluid?.src}`} />
			</SEO>
			<div className={styles.container}>
				<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_STORIES}>
					<ContentLayout>
						<h1>Stories</h1>
						<div className={styles.storyWrapper}>
							<div className={styles.row}>
								<p className={styles.date}>{createdAt}</p>
								<p>
									<Link to={ROUTES.newsroom.stories}>{intl.formatMessage({ id: 'close' })}</Link>
								</p>
							</div>
							<h2 className={styles.title}>{title}</h2>
							<RenderContent
								content={content}
								contentPhotoType={contentPhotoType}
								photoAsset={photo}
								photoFullAsset={photoFull}
							/>
						</div>
						<p>
							<Link to={ROUTES.newsroom.stories}>{intl.formatMessage({ id: 'view_all_stories' })}</Link>
						</p>
					</ContentLayout>
				</QuickBottomNavigationWrapper>
			</div>
		</Layout>
	);
};

const RenderContent = ({ content, contentPhotoType, photoAsset, photoFullAsset }) => {
	const photoSrc = photoAsset && photoAsset.fluid;
	if (contentPhotoType === CONTENTFUL_STORY_PHOTO_TYPE.noPhoto) {
		return <div className={styles.text}>{RichTextRenderer(content)}</div>;
	} else if (contentPhotoType === CONTENTFUL_STORY_PHOTO_TYPE.highPhoto) {
		return (
			<section className={styles.contentWrapper}>
				<div className={styles.text}>{RichTextRenderer(content)}</div>
				<div className={styles.photoWrapper}>
					{photoSrc && (
						<Image className={styles.photo} photo={photoAsset} alt="story photo" previewPhoto={photoAsset} />
					)}
				</div>
			</section>
		);
	} else {
		return (
			<section className={styles.wideContentWrapper}>
				<Image className={styles.photo} photo={photoAsset} alt="story photo" previewPhoto={photoFullAsset} />
				{photoSrc && <div className={styles.text}>{RichTextRenderer(content)}</div>}
			</section>
		);
	}
};

export default StoriesTemplate;
